import { Box, Heading, Text, VStack } from "@chakra-ui/react"
import { useContext } from "react"
import { ThemeContext } from "../context/ThemeContext"
import { faHtml5, faCss3, faJs, faReact, faNodeJs, faGitAlt, faFigma, faPython, faWordpress} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase } from "@fortawesome/free-solid-svg-icons"

const Perfil = () => {

    const skills = [
        {
            skillName: "HTML 5",
            icon: faHtml5,
        },
        {
            skillName: "CSS 3",
            icon: faCss3,
        },
        {
            skillName: "JavaScript",
            icon: faJs
        },
        {
            skillName: "React",
            icon: faReact
        },
        {
            skillName: "Node.js",
            icon: faNodeJs
        },
        {
            skillName: "Python",
            icon: faPython
        },
        {
            skillName: "SQL",
            icon: faDatabase
        },
        {
            skillName: "Git",
            icon: faGitAlt
        },
        {
            skillName: "Figma",
            icon: faFigma
        },
        {
            skillName: "Wordpress",
            icon: faWordpress
        }
    ]

    const {theme} = useContext(ThemeContext)
    
    return (
        <Box
            background={theme.bg.perfil}
            transitionProperty="background" 
            transitionDuration="0.3s"
            id="perfil"
        >
                <VStack>
                    <Heading
                        as="h1"
                        fontSize="50px"
                        padding="5rem 0 2rem 0"
                    >
                        Acerca de mi
                    </Heading>
                    <Box
                        display='flex'
                        justifyContent='center'
                    >
                        <Text 
                            fontSize="1.6rem"
                            width='90%'
                            textAlign='center'
                        >
                            Saludos Humano 🖖, soy un desarrollador de software autodidacta con gran interes en el aprendizaje constante, mi objetivo personal en el mundo tecnologico es poder transformar ideas en algo "real" a través de la programación 👨‍💻
                        </Text>
                    </Box>
                    <Box>
                        <Heading
                            as="h4"
                            textAlign='center'
                            padding='5rem 0 2rem 0'
                            fontSize='2.5rem'
                        >
                            Tecnologías
                        </Heading>
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            width="100%"
                        >
                            <Box
                                className="skills-container"
                            >
                                {
                                    skills.map((skill)=>(
                                        <Box
                                            display="flex"
                                            flexDirection='column'
                                            border="solid 2px #000"
                                            borderRadius="1rem"
                                            alignItems="center"
                                            justifyContent='center'
                                            padding="1rem"
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <FontAwesomeIcon fontSize='2rem' icon={skill.icon} />
                                            </Box>
                                            <Heading as="h5" fontSize="1.3rem">{skill.skillName}</Heading>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                </VStack>
        </Box>
    )
}

export default Perfil