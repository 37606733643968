import { Box, Heading } from "@chakra-ui/react"
import Proyecto from "./Proyecto"
import anonymouspc from "../images/APC.png"
import portafolioImg from "../images/portafolio_vanila.png"
import { useContext } from "react"
import { ThemeContext } from "../context/ThemeContext"

const Projects = () => {
    const {theme} = useContext(ThemeContext)
    const proyectos = [
        {
            img: portafolioImg,
            title: "Porfatolio Vanila",
            url: "https://henry-gc.github.io/",
            description: "Primera version de mi portafolio web, desarrollado en HTML CSS y JavaScript (vanila)"
        },
        {
            img: anonymouspc,
            title: "Anonymous PC",
            url: "https://anonymouspc.netlify.app/",
            description: 'E-commerce desarrollado para la tienda de equipos informaticos "Anonymous PC"'
        }
    ]
    return (
        <Box
            background={theme.bg.proyectos}
            transitionProperty="background" 
            transitionDuration="0.3s"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                color={theme.color}
                transitionProperty="color" 
                transitionDuration="0.3s"
                padding="5%"
            >
                <Heading
                    as="h1" id="proyects"
                    alignSelf="self-start">
                        Proyectos
                </Heading>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="2rem"
                    padding="3%"
                >
                    {proyectos.map((proyecto)=>(
                        <Proyecto
                            key={proyecto.title}
                            img={proyecto.img}
                            title={proyecto.title}
                            description={proyecto.description}
                            url={proyecto.url}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default Projects