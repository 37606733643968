import { Box, Heading, Image, Text } from "@chakra-ui/react"

const Proyecto = (props) =>{
    return (
        <Box
            background="#999"
            borderRadius="10px"
            padding="5px"
        >
            <Image
                src={props.img}
                borderRadius="10px"
                height="10rem"
            />
            <Heading><a href={props.url}>{props.title}</a></Heading>
            <Text>{props.description}</Text>
        </Box>
    )
}
export default Proyecto