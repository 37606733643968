import "./buttonTheme.css"
import React, { createContext, useContext, useState } from "react";

export const ThemeContext = createContext(null)

const ThemeProviderApp = ({children}) =>{

    const light ={
        bg: {
            landing: "#fff",
            perfil: "#fff",
            proyectos: "#fff",
            contactos: "#fff"
        },
        color: "#000"
    };
    const dark = {
        bg: {
            landing: "#000",
            perfil: "#000",
            proyectos: "#000",
            contactos: "#000"
        },
        color: "#fff"
    }

    const[theme,setTheme]= useState(light)

    const toogleTheme = ()=>{
        setTheme(theme.bg.landing === "#fff" ? dark : light);
    }

    return (
    <ThemeContext.Provider value={{theme,toogleTheme}}>
        {children}
    </ThemeContext.Provider>
)}

export default ThemeProviderApp

export const ButtonTheme = () =>{

    const {theme,toogleTheme} = useContext(ThemeContext)

    const handleChange = ()=>{
        toogleTheme()
    }

    return (
        <>
            <label className="switch">
                <input type="checkbox" checked={theme.bg.landing==="#fff"?false:true} id="modeSwitch" onChange={handleChange}/>
                <span className="slider"></span>
            </label>
        </>
    )
}