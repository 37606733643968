import { Box } from "@chakra-ui/react"

const Footer = () => {
    return (
        <Box
            display='flex'
            background='#000'
            color='white'
            height='3rem'
            alignItems='center'
            justifyContent='center'
            fontSize='1.2rem'
            gap='1rem'
        >
            <i class="fa-regular fa-copyright"></i>Henry GC
        </Box>
    )
}

export default Footer