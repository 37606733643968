import React, { useContext } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Textarea
} from "@chakra-ui/react";
import * as Yup from 'yup';
import { ThemeContext } from "../context/ThemeContext";

const Contact = () => {

  const {theme} = useContext(ThemeContext)

  const formik = useFormik({
    initialValues: {
      visitName: "",
      email: "",
      comment: ""
    },
    onSubmit: (values) => {console.log("hola")},

    validationSchema: Yup.object({
      visitName: Yup.string().required("Required"),
      email: Yup.string().email().required("Required"),
      comment: Yup.string().required("Required")
    }),
  });

  return (
      <Box
        display="flex"
        justifyContent="center"
        padding="5% 0"
        background={theme.bg.contactos}
        transitionProperty="background" 
        transitionDuration="0.3s"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
          justifyContent="center"
          color={theme.color}
          transitionProperty="color" 
          transitionDuration="0.3s"
          gap={10}
        >
          <Heading as="h1" id="contact">
            Contacto
          </Heading>
          <Box
            alignSelf="center"
            width="90%"
          >
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={2}>
                <FormControl isInvalid={formik.touched.visitName && formik.errors.visitName}>
                  <FormLabel htmlFor="visitName">Nombre</FormLabel>
                  <Input
                    id="visitName"
                    name="visitName"
                    {...formik.getFieldProps("visitName")}
                  />
                  <FormErrorMessage>{formik.errors.visitName}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                  <FormLabel htmlFor="email">Correo Electrónico</FormLabel>
                  <Input
                    id="email"
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formik.touched.comment && formik.errors.comment}>
                  <FormLabel htmlFor="comment">Mensaje</FormLabel>
                  <Textarea
                    id="comment"
                    name="comment"
                    height={250}
                    {...formik.getFieldProps("comment")}
                  />
                  <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
                </FormControl>
                <Button type="submit" colorScheme="gray" width="full">
                  Enviar
                </Button>
              </VStack>
            </form>
          </Box>
        </Box>
      </Box>
  );
};

export default Contact;
