import { Box,HStack, VStack, Heading } from "@chakra-ui/react"
import { faLinkedin, faGithub, faStackOverflow } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { ThemeContext } from "../context/ThemeContext"
import miImagenDeFondo from "../images/escritorio.png"

const Landing = () => {

    const {theme} = useContext(ThemeContext)
 
    const socials = [
        {
            url: "1",
            icon: faLinkedin
        },
        {
            url: "2",
            icon: faGithub
        },
        {
            url: "3",
            icon: faStackOverflow
        }
    ]

    return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="110vh"
                color={theme.color}
                transitionProperty="color" 
                transitionDuration="0.3s"
                id="title"
                backgroundImage={`url(${miImagenDeFondo})`}
                backgroundSize='cover'
                >
                <VStack
                    color='#fff'
                >
                    <Heading as="h1" fontSize="1.8rem">Henry González</Heading>
                    <Heading as="h2" fontSize="2.5rem">Desarrollador Web</Heading>
                    <HStack
                        fontSize='2.5rem'
                        gap='1rem'
                    >
                    {socials.map((social)=>(
                        <a 
                        href={social.url}
                        key={social.url}
                        >
                            <FontAwesomeIcon icon={social.icon} key={social.url}/>
                        </a>
                    ))}
                </HStack>
                </VStack>
            </Box>
        
    )
}

export default Landing