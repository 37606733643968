import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Header from './components/Header';
import Landing from './components/Landing';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Perfil from './components/Perfil';
import ThemeProviderApp from './context/ThemeContext';
import Footer from './components/Footer';

const theme = extendTheme({
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto', sans-serif",
  },
});

function App() {

  return (
    <ThemeProviderApp>
      <ChakraProvider theme={theme}>
        <main>
          <Header />
          <Landing />
          <Perfil />
          <Projects />
          <Contact />
          <Footer/>
        </main>
      </ChakraProvider>
    </ThemeProviderApp>
  );
}

export default App;
