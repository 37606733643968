import { HStack, Box } from "@chakra-ui/react"
import { ButtonTheme, ThemeContext } from "../context/ThemeContext";
import { useContext, useEffect, useRef} from "react";


const Header = () => {

    const title = "<Henry GC/>"

    const headerRef = useRef(null);

    useEffect(() => { 
        let prevScrollPos = window.scrollY; 
      
        const handleScroll = () => { 
          const currentScrollPos = window.scrollY; 
          const headerElement = headerRef.current; 
          if (!headerElement) { 
            return; 
          } 
          if (prevScrollPos > currentScrollPos) { 
            headerElement.style.transform = "translateY(0)"; 
          } else { 
            headerElement.style.transform = "translateY(-200px)"; 
          } 
          prevScrollPos = currentScrollPos; 
        } 
        window.addEventListener('scroll', handleScroll) 
      
        return () => { 
          window.removeEventListener('scroll', handleScroll) 
        } 
      }, []); 

    // const {theme} = useContext(ThemeContext)

    const handleClick = (props) => () =>{
        const id = `${props}`
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    return (
        <Box
            position="fixed"
            zIndex="1000"
            top={0} 
            left={0} 
            right={0} 
            translateY={0} 
            transitionProperty="transform" 
            transitionDuration=".3s" 
            transitionTimingFunction="ease-in-out"
            ref={headerRef} 
            bgColor="black"
        >
            <HStack
                justifyContent="space-between"
            >
                <HStack
                    color="#fff"
                    fontSize="18px"
                    fontWeight="700"
                >
                    <a href="#Portafolio" onClick={handleClick("title")}>{title}</a>
                </HStack>
                <HStack
                    color="#fff"
                    fontSize="18px"
                >
                    <a href="#Acerca_de_mi" onClick={handleClick("perfil")}>Perfil</a>
                    <a href="#Proyectos" onClick={handleClick("proyects")}>Proyectos</a>
                    <a href="#Contacto" onClick={handleClick("contact")}>Contacto</a>
                </HStack>
            </HStack>
        </Box>
        
    )
}

export default Header